<template>
    <div
        class="settings-page dF fC pb-5"
        :class="$mq == 'sm' ? 'px-4' : 'container container-2'"
    >
        <div>
            <a-modal
                title="A Credit Card is required to set up an instance"
                v-model="needsPayment"
                @ok="$router.push('/instance/create')"
                okText="Activate Project"
            >
                <p class="font-size-18">
                    Your credit card will not be charged for the first 30 days
                    of use. Please click the button below to get started
                </p>
            </a-modal>
            <a-tabs size="large" default-active-key="1">
                <div slot="tabBarExtraContent" class="dF aC" style="gap: 25px">
                    <a-input
                        placeholder="Search your projects..."
                        v-model="query"
                        ref="userNameInput"
                        size="large"
                        v-if="$mq !== 'sm'"
                    >
                        <a-tooltip slot="prefix" title="Search your projects" overlayClassName="change-tooltip-color">
                            <a-icon type="search" style="color: black" />
                        </a-tooltip>
                    </a-input>
                    <a-button
                        class="aC"
                        type="primary"
                        size="large"
                        @click="checkPaymentBeforeCreating"
                    >
                        <a-icon type="plus" />CREATE NEW</a-button
                    >
                </div>
                <a-tab-pane key="1" tab="Projects">
                    <div class="dF aC">
                        <div class="dF aC f1">
                            <h4>All Projects</h4>
                        </div>
                        <div class="dF" style="gap: 25px; font-size: 30px">
                            <em
                                @click="view = 'grid'"
                                :style="
                                    view === 'grid'
                                        ? 'color:var(--orange)'
                                        : 'color:#D3D0D6'
                                "
                                style="cursor: pointer; font-size: 25px"
                                class="fa fa-th"
                            />
                            <em
                                @click="view = 'list'"
                                :style="
                                    view === 'list'
                                        ? 'color:var(--orange)'
                                        : 'color:#D3D0D6'
                                "
                                style="cursor: pointer; font-size: 25px"
                                class="fa fa-th-list"
                            />
                            <a-select
                                v-model="projectsToShow"
                                style="width: 175px"
                            >
                                <a-select-option value="all">
                                    All
                                </a-select-option>
                                <a-select-option value="highrise">
                                    High Rise
                                </a-select-option>
                                <a-select-option value="lowrise">
                                    Low Rise
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <AllInstanceTab
                        class="mt-3"
                        :page="'project'"
                        :instances="queriedInstances"
                        :view="view"
                    />
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>
<script>
const AllInstanceTab = () => import('@/components/common/AllInstanceTab')
import { mapActions } from "vuex";

const labelCol = {
    xs: { span: 24 },
    sm: { span: 4 },
};
const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 12 },
};

export default {
    components: {
        AllInstanceTab,
    },
    data: function () {
        return {
            loading: false,
            labelCol,
            wrapperCol,
            query: "",
            view: "grid",
            projectsToShow: "all",
            needsPayment: false,
        };
    },
    computed: {
        instances() {
            return this.$store.state.instances;
        },
		queriedInstances() {
			let instances = JSON.parse(JSON.stringify(this.instances))
			instances = instances.sort((a, b) => {
				var textA = a.name.toUpperCase();
				var textB = b.name.toUpperCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});
			const query = this.query.trim().toLowerCase();
			if (query) {
				instances = instances.filter((x) => {
					let name = x.name.trim().toLowerCase();
					let readableId = x?.readableId?.trim()?.toLowerCase() || '';
					return name.includes(query) || readableId.includes(query);
				});
			}
			return instances
				.filter((x) => {
					if (this.projectsToShow === "all") {
						return true;
					} else {
						return x.productType === this.projectsToShow;
					}
				})
				.map((x, i) => {
					const instance = {
						...x,
						allChild: x.children,
						key: i,
					};
					delete instance.children;
					return instance;
				});
		},

        editInstance() {
            return this.$store.state.editDrawer.show;
        },
    },

    methods: {
        checkPaymentBeforeCreating() {
            this.$api.get("/payment-methods").then(({ data }) => {
                if (data.length) {
                    this.$router.push("/instance/create");
                } else {
                    this.needsPayment = true;
                }
            }).catch((err) => console.error(err));
        },
    },
    mounted() {
        if (this.$route.path.includes("projects")) {
            let obj = document.querySelector(".first-bar-left");
			if(obj) {
				obj.style.position = "fixed";
				obj.style.width = "225px";
			}
        }
    },
};
</script>

<style lang="scss">
.container-2 {
    max-width: 100vw;
    margin-left: 0;
    margin-right: 0;
    padding-right: 25px;
    padding-left: 25px;
}
.profile-card {
    text-align: center;
}
.ant-card-actions > li:not(:last-child) {
    border-right: none;
}
.footer-button {
    color: var(--black);
    transition: color 0.2s ease-in;
}
.footer-button:hover {
    color: var(--orange);
    transition: color 0.2s ease-in;
}
.ant-form-item-label > label::after {
    content: "";
}
.settings-page {
    background-color: #f7f5f9;
}
</style>
